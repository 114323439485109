// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.popover, .tooltip-inner {
  color: $gray;
  line-height: 1.5em;
  background: $white-color;
  border: none;
  border-radius: $border-radius-base;
  @include shadow-8dp();
}

.popover {
  padding: 0;
  @include shadow-16dp();

  &.left,
  &.right,
  &.top,
  &.bottom {
    > .arrow {
      border: none;
    }
  }
}

.popover-title {
  background-color: $white-color;
  border: none;
  padding: 15px 15px 5px;
  font-size: $font-size-h4;
}

.popover-content {
  padding: 10px 15px 15px;
  line-height: 1.4;
}

.tooltip.in {
  opacity: 1;
  @include transform-translate-y(0px);
}

.tooltip {
  opacity: 0;
  transition: opacity, transform .2s ease;
  @include transform-translate-y(5px);

  &.left {
    .tooltip-arrow {
      border-left-color: $white-color;
    }
  }
  &.right {
    .tooltip-arrow {
      border-right-color: $white-color;
    }
  }
  &.top {
    .tooltip-arrow {
      border-top-color: $white-color;
    }
  }
  &.bottom {
    .tooltip-arrow {
      border-bottom-color: $white-color;
    }
  }
}

.tooltip-inner {
  padding: 10px 15px;
  min-width: 130px;
}
