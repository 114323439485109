.form-group {
}

.checkbox {
  label {
    cursor: pointer;
    padding-left: 0; // Reset for Bootstrap rule
    color: $mdb-checkbox-label-color;
    @include mdb-label-color-toggle-focus();
  }

  // Hide native checkbox
  input[type=checkbox] {
    opacity: 0;
    position: absolute;
    margin: 0;
    z-index: -1;
    width: 0;
    height: 0;
    overflow: hidden;
    left: 0;
    pointer-events: none;
  }

  .checkbox-material {
    vertical-align: middle;
    position: relative;
    top: 1px;
    padding-right: 5px;
    display: inline-block;

    &:before {
      display: block;
      position: absolute;
      left: 0;
      content: "";
      background-color: rgba(0, 0, 0, .84);
      height: $mdb-checkbox-size;
      width: $mdb-checkbox-size;
      border-radius: 100%;
      z-index: 1;
      opacity: 0;
      margin: 0;
      top: 0;
      @include transform-scale3d(unquote('2.3,2.3,1'));
    }

    .check {
      position: relative;
      display: inline-block;
      width: $mdb-checkbox-size;
      height: $mdb-checkbox-size;
      border: 1px solid $mdb-checkbox-border-color;
      overflow: hidden;
      z-index: 1;
      border-radius: $border-radius-base;
    }
    .check:before {
      position: absolute;
      content: "";
      transform: rotate(45deg);
      display: block;
      margin-top: -3px;
      margin-left: 7px;
      width: 0;
      height: 0;
      background: red;
      box-shadow: 0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0,
      0 0 0 0 inset;
      @include animation(checkbox-off $mdb-checkbox-animation-check forwards);
    }
  }

  input[type=checkbox] {

    &:focus + .checkbox-material .check:after {
      opacity: 0.2;
    }

    &:checked {

      & + .checkbox-material .check {
        background: $mdb-checkbox-checked-color;
      }

      & + .checkbox-material .check:before {
        color: #FFFFFF;
        box-shadow: 0 0 0 10px,
        10px -10px 0 10px,
        32px 0 0 20px,
        0px 32px 0 20px,
        -5px 5px 0 10px,
        20px -12px 0 11px;
        @include animation(checkbox-on $mdb-checkbox-animation-check forwards);
      }

      & + .checkbox-material:before {
        @include animation(rippleOn $mdb-checkbox-animation-ripple);
      }

      & + .checkbox-material .check:after {
        //background-color: $brand-success; // FIXME: seems like tho wrong color, test and make sure it can be removed
        @include animation(rippleOn $mdb-checkbox-animation-ripple forwards);
      }
    }

    &:not(:checked) {
      & + .checkbox-material:before {
        @include animation(rippleOff $mdb-checkbox-animation-ripple);
      }

      & + .checkbox-material .check:after {
        @include animation(rippleOff $mdb-checkbox-animation-ripple); // Ripple effect on uncheck

      }
    }
  }

  // Style for disabled inputs
  fieldset[disabled] &,
  fieldset[disabled] & input[type=checkbox],
  input[type=checkbox][disabled] ~ .checkbox-material .check,
  input[type=checkbox][disabled] + .circle {
    opacity: 0.5;
  }

  input[type=checkbox][disabled] ~ .checkbox-material .check {
    border-color: #000000;
    opacity: .26;
  }

  input[type=checkbox][disabled] + .checkbox-material .check:after {
    background-color: $mdb-text-color-primary;
    transform: rotate(-45deg);
  }
}

@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px,
    10px -10px 0 10px,
    32px 0 0 20px,
    0px 32px 0 20px,
    -5px 5px 0 10px,
    15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px,
    10px -10px 0 10px,
    32px 0 0 20px,
    0px 32px 0 20px,
    -5px 5px 0 10px,
    20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px,
    10px -10px 0 10px,
    32px 0 0 20px,
    0px 32px 0 20px,
    -5px 5px 0 10px,
    20px -12px 0 11px;
  }
}

@keyframes rippleOn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rippleOff {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}
