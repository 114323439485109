.dropdown-menu {
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);

  .divider {
    background-color: rgba(0, 0, 0, .12);
  }

  li > a {
    font-size: $mdb-dropdown-font-size;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: $border-radius-small;
    @include transition($fast-transition-time, $transition-linear);

    &:hover,
    &:focus {
      @include shadow-8dp();

    }
  }

  li {
    position: relative;
    a:hover,
    a:focus,
    a:active {
      background-color: $brand-primary;
      color: #FFFFFF;
    }
  }

  .divider {
    margin: 5px 0;
  }
}

@media (min-width: $screen-md-min) {
  .dropdown {
    .dropdown-menu {
      @include transition($fast-transition-time, $transition-linear);
      margin-top: -20px;
      opacity: 0;
      visibility: hidden;
      display: block;
    }

    &.open {
      .dropdown-menu {
        opacity: 1;
        visibility: visible;
        margin-top: 1px;
      }
    }
  }

}
