// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.alert {
  border: 0;
  border-radius: 0;

  padding: 20px 15px;
  line-height: 20px;

  //@include shadow-z-2();

  b {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    font-size: $font-size-small;
  }
  // SASS conversion note: please mirror any content change in _mixins-shared.scss alert-variations-content
  @include alert-variations(unquote(".alert"), unquote(""), $mdb-text-color-light);

  &-info, &-danger, &-warning, &-success {
    color: $mdb-text-color-light;
  }

  &-default {
    a, .alert-link {
      color: $mdb-text-color-primary;
    }
  }

  .alert-icon {
    display: block;
    float: left;
    margin-right: $margin-base;

    i {
      margin-top: -7px;
      top: 5px;
      position: relative;
    }
  }
}
