// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

body {
  background-color: #CCCCCC;
  &.inverse {
    background: #333333;
    &, .form-control {
      color: $mdb-text-color-light;
    }
    .modal,
    .panel-default,
    .card {
      &,
      .form-control {
        background-color: initial;
        color: initial;
      }
    }

  }
}

.life-of-material-kit {
  background: #FFFFFF;
}

body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4 {
  font-family: $font-family-sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a, a:hover, a:focus {
  color: $link-color;

  & .material-icons {
    vertical-align: middle;
  }
}

/*           Animations              */

.animation-transition-general {
  @include transition($general-transition-time, $transition-linear);
}

.animation-transition-slow {
  @include transition($slow-transition-time, $transition-linear);
}

.animation-transition-fast {
  @include transition($fast-transition-time, $transition-ease);
}

@import 'form';
//@import '_welljumbo';   // Not designed yet by Creative Tim, you can use it as it is
@import 'buttons';
@import 'checkboxes';
@import 'togglebutton';
@import 'radios';
@import 'inputs';
@import 'pagination';
@import 'labels';
@import 'pills';
@import 'footers';

legend {
  border-bottom: 0;
}

//@import '_lists';  // Not designed yet by Creative Tim, you can use it as it is
@import 'navbar';

@import 'dropdown';
@import 'info-areas';
@import 'alerts';
@import 'progress';
@import 'typography';
@import 'tabs';
@import 'popups';
@import 'carousel';
@import 'cards';
@import 'dialogs';
@import 'panels';
@import 'dividers';

@import 'images';

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  &:focus {
    outline: 0;
  }
}

a:focus, a:active,
button:active, button:focus, button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

@import 'sections';

// External plugins
// @import 'plugins/_plugin-snackbarjs';   // Not designed yet by Creative Tim, you can use it as it is
@import 'plugins/plugin-nouislider';
//@import 'plugins/_plugin-selectize';     // Not designed yet by Creative Tim, you can use it as it is
@import 'plugins/plugin-dropdownjs';
@import 'plugins/plugin-datepicker';
